import { getAbsoluteUrl } from '@/src/utils/helpers/get-absolute-url';

type PrepareParameters = {
  pathname?: string | any;
  searchParams?: any;
  isFullPath?: boolean;
};

export function prepare_virtualPageUrl({ pathname = '', searchParams, isFullPath = false }: PrepareParameters): string {
  if (searchParams && Object.keys(searchParams).length) {
    var urlWithSearchParams =
      '?' +
      Object.keys(searchParams)
        .map((key) => {
          if (Array.isArray(searchParams[key])) {
            return searchParams[key]?.map((item: any) => `${key}=${item}`).join('&');
          }
          return `${key}=${searchParams[key]}`;
        })
        .join('&');
    if (isFullPath) {
      let host = getAbsoluteUrl();
      return `${host}${pathname}/${urlWithSearchParams}`;
    }
    return pathname + urlWithSearchParams;
  }
  return pathname;
}
