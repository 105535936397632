export const IS_SERVER = typeof window === 'undefined';
export function getProtocol() {
  const isProd = process.env.APP_NODE === 'production';
  if (isProd) return 'https://';
  return 'http://';
}
export function getAbsoluteUrl() {
  //get absolute url in client/browser
  if (!IS_SERVER) {
    return location.origin;
  }
  //get absolute url in server.
  const protocol = getProtocol();
  if (process.env.HOST) {
    return `${protocol}${process.env.HOST}`;
  }
  return `${protocol}${process.env.HOST}`;
}
